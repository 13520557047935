.careerScreenWrapper {
    padding-left: 15%;
    padding-right: 15%;
}

.skillScreenWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 15%;
    padding-right: 15%;
}

.projectScreenWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; /*space-evenly*/
    padding-left: 5%;
    padding-right: 5%;
}

.aboutScreenWrapper {
    padding-left: 15%;
    padding-right: 15%;
}

.contentsColumn {
    display: flex;
    flex-direction: column;
}

.contentsRow {
    display: flex;
    flex-direction: row;
}

.contentsCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.imageContainer {
    border-radius: 10px;
    width: 80%;
    height: 100%;
}
.wrapper {
    display: flex;
    flex-direction: row;
    margin: 10px 10px 0px;
}

.icon {
    padding: 0px 10px 0px;
}

#linkedin {
    color: rgb(93, 147, 235);
}

#linkedin:hover {
    color: rgb(138, 176, 236);
}

#github {
    color: rgb(160, 94, 222);
}

#github:hover {
    color: rgb(186, 143, 226);
}

#mail {
    color: rgb(93, 147, 235);
}

#mail:hover {
    color: rgb(138, 176, 236);
}
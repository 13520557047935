.imageContainer {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    margin-right: 10px;
}

.contentsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}
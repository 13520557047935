@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.profileWrapper {
    display: flex;
    flex-direction:column;
    align-items: center;
}

.aboutWrapper {
    padding: 0 10% 0;
}

.imageContainer {
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 3px;
}

.info{
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    padding: 20px;
    font-family: monospace;
}

.title {
    color: #282c34;
    font-family: 'Press Start 2P', cursive;
    transform: scale(0.8,1.5);
}
  
.title::before {
    content: 'Brayden Klemens';
    color: #fff;
    width: 100%;
    white-space: nowrap;
    position:absolute;
    overflow: hidden;
    /* border-right: 3px solid #fff; */
    animation: animate 4s ease-out;
}

@keyframes animate {
0%, 10% ,100%
{
    width: 0;
}
70%, 90%, 100%
{
    width: 100%;
}
}


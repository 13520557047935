
.imageContainer {
    width: 350px; /*100%*/
    height: 350px;/*100%*/
    border-radius: 10px;
    box-shadow: 1px 2px 8px rgba(0,0,0,0.26);
}

.buttonContainer{
    display: flex;
    flex-direction: row;
}

.btn {
    /* style */
    background-color: transparent;
    color: black;
    border: none;
    font-size: 32px;
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn:hover {
    cursor: pointer;
    text-shadow: 1px 1px 1px rgb(103, 99, 99);
}
.cardWrapper {
    background-color: white;
    color: black;
    transition: box-shadow 200ms ease-in-out;
    border-radius: 10px;
    width: 350px; /*25%*/
    margin: 15px;
    padding: 15px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.cardWrapper:hover {
    box-shadow: 8px 10px 8px black;
}

.content {
    height: 60px;
    overflow: hidden;
}

.iconWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.icon {
    width: max-content;
    padding: 0 10px 0;
}

#github {
    color: #782E9E;
}

#github:hover {
    color: rgb(195, 148, 239);
}

#download {
    color: rgb(59, 119, 217);
}

#download:hover {
    color: rgb(138, 176, 236);
}
.tabWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 15% 0;
    position: sticky;
    top: 0;
    background-color: #282c34
}

@media (max-width: 600px) {
    .tabWrapper {
        flex-wrap: wrap;
        position: static;
    }
}